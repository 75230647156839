.footer {
    background-color: var(--background-color);
    color: var(--text-color);
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .footer.sticky-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }
  
  .social-links {
    margin: 1rem 0;
    padding: 0;
  }

  .footer-text {
    font-style: italic;
    padding-bottom: 15px;
  }
  
  .social-links li {
    list-style: none;
  }
  
  .social-links a {
    color: var(--link-color);
    font-size: 1.4rem;
    text-decoration: none;
  }
  
  .theme-selector-container {
    display: flex;
    justify-content: center;
    z-index: 1010; /* Ensure it is above other content */
    width: 100%;
    height: 100%;
    margin-bottom: 30px;
  }
  
    @media (min-width: 992px) {
    .social-links li {
      display: none;
    }
  
    .social-links .spotify,
    .social-links .tiktok,
    .social-links .instagram,
    .social-links .facebook {
      display: none;
    }
  }
