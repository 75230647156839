:root {
  --text-color: black;
  --button-color: #f05222;
  --button-text-color: white; 
  --border-color: #ddd;
}

body.orange-theme {
  --text-color: white;
  --button-color: #ddd;
  --button-text-color: #f05222; 
  --border-color: #ddd;
}

body.white-theme {
  --text-color: #f05222;
  --button-color: #f05222;
  --button-text-color: white; 
  --border-color: #f05222;
}

body.gray-theme {
  --text-color: black;
  --button-color: #f05222;
  --button-text-color: black; 
  --border-color: grey;
}

#band-image {
  max-width: 100%;
  /* max-height: calc(80vh - var(--header-height) - var(--footer-height)); */
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.image {
  position: relative;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--header-height) - var(--footer-height));
}

.main-container {
  height: 100vh;
  background-color: --background-color;
}

.section {
  width: 100%;
}

/* .sections {
  width: 100%;
  height: calc(100vh - var(--header-height) - var(--footer-height));
  display: flex;
  align-items: center;
  justify-content: center;
} */

.main-button { 
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-link {
  background-color: var(--button-color);
  color: var(--button-text-color);
  border: none;
  padding: 0.75rem 1.5rem;
  text-align: center;
  text-decoration: none;
  font-size: medium;
  font-family: 'Favorit', Arial, sans-serif;
}

.main-link:hover {
  text-decoration: underline;
}
