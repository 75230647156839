@font-face {
  font-family: 'HelveticaBold';
  src: url('../../fonts/Helvetica-Bold.eot');
  src: url('../../fonts/Helvetica-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/Helvetica-Bold.woff2') format('woff2'),
      url('../../fonts/Helvetica-Bold.woff') format('woff'),
      url('../../fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 100vh; */
  /* height: calc(100vh - 60px - 40px); */
  background-color: var(--background-color);
  color: var(--text-color);
  height: calc(90vh - var(--header-height) - var(--footer-height));
}

.contact-item {
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 1000px;
  margin-bottom: 20px;
  padding: 10px;
  padding: 15px 0;
  border-bottom: 1px solid var(--border-color);
  /* border-radius: 5px; */
}

.contact-title {
  /* font-family: 'Helvetica73-Extended', Arial, sans-serif; */
  font-family: 'HelveticaBold', Arial, sans-serif;
  flex: 1;
  text-align: left;
  font-size: 1.2rem;
}

.contact-info {
  flex: 2;
  text-align: right;
}

.contact-name {
  font-size: 1.2rem;
  margin-right: 10px;
  font-family: 'HelveticaBold', Arial, sans-serif;
}

.contact-link {
  color: var(--link-color);
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}

@media (max-width: 767.98px) {
  .contact-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-bottom: 0px solid var(--border-color);
  }

  .contact-title,
  .contact-info {
    text-align: center;
  }

  .contact-name,
  .contact-link {
    margin-right: 0;
  }

  .contact-name {
    display: none;
  }
}
