:root {
  --background-color: #afb8b6;
  --link-color: black;
  --max-width: 100vw;
  /* --min-width: 500px; */
  --header-height: 60px;
  --footer-height: 40px;
  --main-padding-top: 20px;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

.App {
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
  background-color: var(--background-color);
  color: var(--link-color);
}

.container {
  width: 100%;
  max-width: var(--max-width);
  /* min-width: 100%; */
  margin-top: var(--header-height);
  margin-bottom: var(--footer-height);
  /* height: calc(100vh - var(--header-height) - var(--footer-height)); */
  box-sizing: border-box;
  overflow-y: auto;
  
}

.main-content {
  /* flex: 1; */
  padding-inline: 30px;
  /* max-height: 100px; */
  /* max-width: var(--max-width); */
}

.header {
  padding-inline: 30px;
}


.social-links {
  margin: 1rem 0;
  padding: 0;
}

.social-links li {
  list-style: none;
}

.social-links a {
  color: var(--link-color);
  font-size: 1.5rem;
  text-decoration: none;
}

.bottom-padding {
  padding-bottom: 100px; /* Adjust the value as needed */
}

@media (max-width: 767.98px) {
  .main-content {
    padding-inline: 5px;
  }
  .bottom-padding {
    padding-bottom: 140px; /* Adjust the value as needed */
  }
}



