/* Header.css */

@font-face {
  font-family: 'Helvetica73-Extended';
  src: url('../fonts/Helvetica73-ExtendedBold.eot');
  src: url('../fonts/Helvetica73-ExtendedBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Helvetica73-ExtendedBold.woff2') format('woff2'),
      url('../fonts/Helvetica73-ExtendedBold.woff') format('woff'),
      url('../fonts/Helvetica73-ExtendedBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Favorit';
  src: url('../fonts/Favorit-Medium.eot');
  src: url('../fonts/Favorit-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Favorit-Medium.woff2') format('woff2'),
      url('../fonts/Favorit-Medium.woff') format('woff'),
      url('../fonts/Favorit-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.header {
  position: fixed;
  top: 0;
  width: 100%; /* Ensure the header spans the full width of the viewport */
  z-index: 1000;
  background-color: var(--background-color);
  padding-inline: 40px;
  padding-top: 36px;
  font-family: 'Favorit', Arial, sans-serif;
}

.header-container {
  width: 100%; 
  margin: 0 auto;
  /* padding: 0 1rem; */
  box-sizing: border-box;
}

#logo-image {
  width: 140px;
  height: auto;
  filter: var(--logo-filter);
  margin-bottom: 10px;
}

.nav-link {
  color: var(--link-color);
  font-size: 1.2rem;
  font-family: 'Helvetica73-Extended', Arial, sans-serif;
  text-decoration: none;
  margin: 0 10px;
}

.nav-link:hover {
  text-decoration: underline;
}

/* Adjust font size for smaller devices */
@media (max-width: 576px) {
  .nav-link {
    font-size: 1rem;
  }

  #logo-image {
    margin-bottom: 0px;
  }  
}

@media (max-width: 768px) {
  /* Adjustments for medium-sized devices */
}
