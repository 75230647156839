:root {
  --text-color: black;
  --button-color: #f05222;
  --button-text-color: white; 
  --border-color: #ddd;
}


body.orange-theme {
  --text-color: white;
  --button-color: #dcddde;
  --button-text-color: #f05222; 
  --border-color: #ddd;
}

body.white-theme {
  --text-color: #f05222;
  --button-color: #f05222;
  --button-text-color: white; 
  --border-color: #f05222;
}

body.gray-theme {
  --text-color: black;
  --button-color: #f05222;
  --button-text-color: black; 
  --border-color: grey;
}


.news-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 140px;
  overflow: hidden;
  
}

.news-photo-container {
  flex: 1;
  padding: 5%; 
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 80vh; */

}

.news-photo {
  max-width: 120%;
  object-fit: contain; /* Ensure the image is fully visible */
  /* border: 1px solid #dcddde; */
  cursor: pointer;
  
}

.news-content {
  flex: 1;
  padding: 15px;
  text-align: center;
}

.news-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 16px;
}

.news-description {
  font-size: 1em;
  font-style: italic;
  margin-bottom: 16px;
}

.news-link {
  background-color: var(--button-color);
  border: none;
  color: var(--button-text-color);
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Favorit', Arial, sans-serif;
}

.news-link:hover {
  text-decoration: underline;
}

@media (max-width: 500px) {
  .news-item {
    flex-direction: column;
    align-items: center;
    /* max-height: 70vh; */
  }

  .news-photo-container,
  .news-content {
    max-width: 100%;
    max-height: 100%;
  }

  .news-photo {
    /* max-height: 100%;
    max-width: 70%; */
  }
}
