/* Music.css */

.music {
  max-width: 100%;
  padding-top: 15px;
  /* height: calc(90vh - var(--header-height) - var(--footer-height)); */
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  /* height: 100vh; */
  height: calc(100vh - var(--header-height) - var(--footer-height));

}

.music h1, .music h2 {
  text-align: center;
}

.music section {
  text-align: center;
}

.music .release-photo {
  height: auto;
}

.music-container {
  padding: 20px;
  /* height: calc(100vh - var(--header-height) - var(--footer-height)); */

}

.spotify-embed {
  margin: 20px 0 0; /* Add space above the Spotify embed */
}

/* Responsive iframe */
.video-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  margin-bottom: 20px; /* Add space below the video */
}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
